/* eslint-disable prettier/prettier */
export default {
  layerSortMap: {
    // <roomId>:<priority>
    '6_BG_L': 'B',
    '6_FG_L': 'X',
    '6_BG_MB': 'B',
    '6_FG_MB': 'X',
    '6_BG_MS': 'B',
    '6_FG_MS': 'X',
    '6_BG_S': 'F',
    '6_FG_S': 'X',
    '72_BG': 'B',
    '72_FG': 'X',
    '228_BG': 'F',
    '228_FG': 'X',
    // <slotId>:<priority>
    // TODO: consider remapping duplicates, e.g. '405_248': '414',
    '93': 'B', //  wandfarbe
    '78': 'D', //  parkett
    '254': 'D', // parkett
    '408': 'D', // parkett
    '80': 'G', //  fussleisten
    '33': 'E', //  bodenfliesen (bad)
    '248': 'E', // bodenfliesen
    '259': 'F', // bodenfliesen (küche)
    '423': 'F', // parkett (abstellraum)
    '36': 'G', //  wandfliesen
    '263': 'Y', // wohnzimmertuer
    '97': 'Z', //  tuerdruecker
    '13': 'J', //  badewanne
    '374': 'J', // badewanne
    '16': 'K', //  badewannenarmatur
    '60': 'L', //  wc
    '64': 'M', //  wc-drueckerplatte
    '54': 'N', //  waschbecken
    '56': 'O', //  waschbeckenarmatur
    '293': 'P', // duschsystem
    '392': 'Q', // dusche
    '69': 'R', //  heizkoerper
    '11685': 'R', // parkett (flur)
    '405_78': 'R', // bodenfliesen (flur)
    '405_248': 'R', // bodenfliesen (flur)
    '228_hallway_floor': 'R', // bodenfliesen (flur)
    '414': 'R', // bodenfliesen (flur)
    '421_78': 'S', // bodenfliesen (abstellraum)
    '425': 'S', // bodenfliesen (abstellraum)
    '427': 'S', // bodenfliesen (abstellraum)
    '228_bedroom_floor': 'S', // bodenbelag (schlafzimmer)
  },
  cascades: {
    // Override foreign selections
    // <catalogId>.<foreignSlotId>:<overrideFilename>
    '5011_GR': {
      '97': null, // setting "no-wohnzimmertür" overrides "türgriffe" to show nothing
    },
    // '228-wohnungsflur-abstellraum': {

    // }
  },
  references: {
    // Create custom slots that reference other selections
    // Must be prefixed with the room ID
    // <roomId>_<customSlotSlug>:<referencedSlotId>
    '228_bedroom_floor': [78, 248],
    '228_hallway_floor': [78, 248],
  },
  fileMap: {
    // Defaults
    // <key>.<roomId>:<fileName>
    // eslint-disable-next-line prettier/prettier
    'Background': {
      'info': { type: 'background' },
      '6_BG_L': 'bathroom/bpd_bathroom_L_BG',
      '6_BG_MB': 'bathroom/bpd_bathroom_MB_bg0135',
      '6_BG_MS': 'bathroom/bpd_bathroom_MS_bg',
      '6_BG_S': 'bathroom/bpd_bathroom_S_bg',
      '72_BG': 'living-kitchen/bpd_livingkitchen_bg',
      '22_BG': 'hallway-storage2/bpd_storage_hallway_bg',
      '228_BG': 'hallway-storage2/bpd_storage_hallway_bg',
    },
    // eslint-disable-next-line prettier/prettier
    'Foreground': {
      'info': { type: 'foreground' },
      '6_FG_L_F': 'bathroom/bpd_bathroom_L_FG_Fuge',
      '6_FG_L': 'bathroom/bpd_bathroom_L_FG',
      '6_FG_MB': 'bathroom/bpd_bathroom_MB_fg',
      '6_FG_MS_F': 'bathroom/bpd_bathroom_MS_fg_Fuge',
      '6_FG_MS': 'bathroom/bpd_bathroom_MS_fg',
      '6_FG_S': 'bathroom/bpd_bathroom_S_fg0001',
      '72_FG': 'living-kitchen/bpd_livingkitchen_foreground0035',
      '228_FG': 'hallway-storage2/bpd_storage_hallway_fg',
    },
    // Dummies
    '4001_W': true,
    '4002_W': true,
    '4003_W': true,
    '4001_B': true,
    '4002_B': true,
    '4003_B': true,
    '4016_PKA_1': true,
    '4025_PKA_2': true,
    '4026_PKA_2': true,
    '4027_PKA_3': true,
    '8001_XX': true,
    '8703': true,
    '8704': true,
    '8705': true,
    // <catalogId>.<slotId>:<fileName>
    '3101_SR90_B_60': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Gateway midnight black', size: '60x60', id: '2546SR90' },
      '33': 'bathroom/bpd_bathroom_L0004',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0004',
      '259': 'living-kitchen/bpd_livingkitchen_0004',
      '405_248': 'hallway-storage2/bpd_storage_hallway0006',
      '414': 'hallway-storage2/bpd_storage_hallway0006',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0004',
    },
    '3101_SR90_B_30': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Gateway midnight black', size: '60x30', id: '2539SR90' },
      '33': 'bathroom/bpd_bathroom_L0005',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0005',
      '259': 'living-kitchen/bpd_livingkitchen_0005',
      '405_248': 'hallway-storage2/bpd_storage_hallway0004',
      '414': 'hallway-storage2/bpd_storage_hallway0004',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0005',
    },
    '3101_SR90_B_M': {
      '33': 'bathroom/bpd_bathroom_L0163', // Gateway Mosaik Midnight Black	2013SR90 missing!
    //   '248': 'living-kitchen/bpd_livingkitchen_floortile0006',
    //   '259': 'living-kitchen/bpd_livingkitchen_0006',
    //   '405_248': 'hallway-storage2/bpd_storage_hallway0006',
    //   '414': 'hallway-storage2/bpd_storage_hallway0006',
    //   '427': 'hallway-storage2/bpd_storage_hallway_storage0006',
    },
    '3100_CT10_B_60': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four creme', size: '60x60', id: '2361CT10' },
      '33': 'bathroom/bpd_bathroom_L0007',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0007',
      '259': 'living-kitchen/bpd_livingkitchen_0007',
      '405_248': 'hallway-storage2/bpd_storage_hallway0007',
      '414': 'hallway-storage2/bpd_storage_hallway0007',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0007',
    },
    '3100_CT10_B_30': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four creme', size: '60x30', id: '2360CT10' },
      '33': 'bathroom/bpd_bathroom_L0008',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0008',
      '259': 'living-kitchen/bpd_livingkitchen_0008',
      '405_248': 'hallway-storage2/bpd_storage_hallway0008',
      '414': 'hallway-storage2/bpd_storage_hallway0008',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0008',
    },
    '3100_CT10_B_M': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four creme', size: '5x5', id: '2363CT10' },
      '33': 'bathroom/bpd_bathroom_L0009',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0009',
      '259': 'living-kitchen/bpd_livingkitchen_0009',
      '405_248': 'hallway-storage2/bpd_storage_hallway0009',
      '414': 'hallway-storage2/bpd_storage_hallway0009',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0009',
    },
    '3100_CT60_B_60': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four hellgrau', size: '60x60', id: '2361CT60' },
      '33': 'bathroom/bpd_bathroom_L0010',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0010',
      '259': 'living-kitchen/bpd_livingkitchen_0010',
      '405_248': 'hallway-storage2/bpd_storage_hallway0010',
      '414': 'hallway-storage2/bpd_storage_hallway0010',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0010',
    },
    '3100_CT60_B_30': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four hellgrau', size: '60x30', id: '2360CT60' },
      '33': 'bathroom/bpd_bathroom_L0011',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0011',
      '259': 'living-kitchen/bpd_livingkitchen_0011',
      '405_248': 'hallway-storage2/bpd_storage_hallway0011',
      '414': 'hallway-storage2/bpd_storage_hallway0011',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0011',
    },
    '3100_CT60_B_M': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four hellgrau', size: '5x5', id: '2363CT60' },
      '33': 'bathroom/bpd_bathroom_L0012',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0012',
      '259': 'living-kitchen/bpd_livingkitchen_0012',
      '405_248': 'hallway-storage2/bpd_storage_hallway0012',
      '414': 'hallway-storage2/bpd_storage_hallway0012',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0012',
    },
    '3100_CT61_B_60': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four mittelgrau', size: '60x60', id: '2361CT61' },
      '33': 'bathroom/bpd_bathroom_L0013',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0013',
      '259': 'living-kitchen/bpd_livingkitchen_0013',
      '405_248': 'hallway-storage2/bpd_storage_hallway0013',
      '414': 'hallway-storage2/bpd_storage_hallway0013',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0013',
    },
    '3100_CT61_B_30': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four mittelgrau', size: '60x30', id: '2360CT61' },
      '33': 'bathroom/bpd_bathroom_L0014',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0014',
      '259': 'living-kitchen/bpd_livingkitchen_0014',
      '405_248': 'hallway-storage2/bpd_storage_hallway0014',
      '414': 'hallway-storage2/bpd_storage_hallway0014',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0014',
    },
    '3100_CT61_B_M': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four mittelgrau', size: '5x5', id: '2363CT61' },
      '33': 'bathroom/bpd_bathroom_L0015',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0015',
      '259': 'living-kitchen/bpd_livingkitchen_0015',
      '405_248': 'hallway-storage2/bpd_storage_hallway0015',
      '414': 'hallway-storage2/bpd_storage_hallway0015',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0015',
    },
    '3100_CT62_B_60': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four dunkelgrau', size: '60x60', id: '2361CT62' },
      '33': 'bathroom/bpd_bathroom_L0016',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0016',
      '259': 'living-kitchen/bpd_livingkitchen_0016',
      '405_248': 'hallway-storage2/bpd_storage_hallway0016',
      '414': 'hallway-storage2/bpd_storage_hallway0016',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0016',
    },
    '3100_CT62_B_30': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four dunkelgrau', size: '60x30', id: '2360CT62' },
      '33': 'bathroom/bpd_bathroom_L0017',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0017',
      '259': 'living-kitchen/bpd_livingkitchen_0017',
      '405_248': 'hallway-storage2/bpd_storage_hallway0017',
      '414': 'hallway-storage2/bpd_storage_hallway0017',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0017',
    },
    '3100_CT62_B_M': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four dunkelgrau', size: '5x5', id: '2363CT62' },
      '33': 'bathroom/bpd_bathroom_L0018',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0018',
      '259': 'living-kitchen/bpd_livingkitchen_0018',
      '405_248': 'hallway-storage2/bpd_storage_hallway0018',
      '414': 'hallway-storage2/bpd_storage_hallway0018',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0018',
    },

    // TODO: new very small mosaic tile, remove again after testing
    'F-BF-RAK-3060-LO-03': {
      'info': { type: 'floortiles', manufacturer: '', name: 'Bad Lounge beige', size: '3x3', id: '' },
      '33': 'bathroom/bpd_bathroom_L0070',
    },

    // TODO: new 2022-12, please correctly sort them
    'T-IT-JEL-213-LOMLÖ-00': {
      'info': { type: 'livingroomdoor', manufacturer: 'Lombardo', name: '', id: '' },
      '263': 'living-kitchen/bpd_livingkitchen_0060',
    },
    'S-DUO-GE-00-00': {
      'info': { type: 'showerbasin', manufacturer: '', name: 'Wandeinlauf', id: '' },
      'influences': [{ renderId: 'Foreground', suffix: '_F' }],
      '392_L': 'bathroom/bpd_bathroom_L0245',
      '392_MS': 'bathroom/bpd_bathroom_MS0245',
    },
    'P-FG-00': {
      'info': { type: 'parquet', manufacturer: '', name: 'Einzelstabparkett', id: '' },
      '78': 'living-kitchen/bpd_livingkitchen_0096',
      '254': null,
      '408': null,
      '423': null,
      '405_78': 'hallway-storage2/bpd_storage_hallway0096',
      '421_78': 'hallway-storage2/bpd_storage_hallway_storage0096',
      '228_bedroom_floor': 'hallway-storage2/bpd_storage_hallway_bedroom0096',
    },
    'S-UBR-GR-00-EUC-00': {
      'info': { type: 'showerarmature', manufacturer: 'Grohe', name: 'Eurostyle Cosmopolitan Brausenarmatur', id: '' },
      '293_L': 'bathroom/bpd_bathroom_L0241',
      '293_MS': 'bathroom/bpd_bathroom_MS0241',
    },
    'S-UBR-GR-00-ESC-00': {
      'info': { type: 'showerarmature', manufacturer: 'Grohe', name: 'Essence Brausenarmatur', id: '' },
      '293_L': 'bathroom/bpd_bathroom_L0243',
      '293_MS': 'bathroom/bpd_bathroom_MS0243',
    },


    '3600_SD7B_B_120': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Hudson clay', size: '120x60', id: '2987SD7B' },
      '33': 'bathroom/bpd_bathroom_L0019',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0003',
      '259': 'living-kitchen/bpd_livingkitchen_Kitchenfloortile0003',
      '405_248': 'hallway-storage2/bpd_storage_hallway0003',
      '414': 'hallway-storage2/bpd_storage_hallway0003',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0003',
    },
    '3600_SD7B_B_60': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Hudson clay', size: '60x60', id: '2987SD7B' },
      '33': 'bathroom/bpd_bathroom_L0002',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0002',
      '259': 'living-kitchen/bpd_livingkitchen_Kitchenfloortile0002',
      '405_248': 'hallway-storage2/bpd_storage_hallway0002',
      '414': 'hallway-storage2/bpd_storage_hallway0002',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0002',
    },
    '3600_SD7B_B_30': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Hudson clay', size: '60x30', id: '2987SD7B' },
      '33': 'bathroom/bpd_bathroom_L0028',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0028',
      '259': 'living-kitchen/bpd_livingkitchen_Kitchenfloortile0028',
      '405_248': 'hallway-storage2/bpd_storage_hallway0027',
      '414': 'hallway-storage2/bpd_storage_hallway0027',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0027',
    },
    '3600_SD7B_B_SF': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Hudson clay', size: '60x7.5', id: '2852SD7B' },
      '33': 'bathroom/bpd_bathroom_L0160',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0160',
      '259': 'living-kitchen/bpd_livingkitchen_Kitchenfloortile0160',
      '405_248': 'hallway-storage2/bpd_storage_hallway0160',
      '414': 'hallway-storage2/bpd_storage_hallway0160',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0160',
    },
    '3600_SD7B_B_M': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Hudson clay', size: '60x7.5', id: '2852SD7B' },
      '33': 'bathroom/bpd_bathroom_L0161',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0161',
      '259': 'living-kitchen/bpd_livingkitchen_Kitchenfloortile0161',
      '405_248': 'hallway-storage2/bpd_storage_hallway0161',
      '414': 'hallway-storage2/bpd_storage_hallway0161',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0161',
    },
    '3601_PL80_B_M': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Pure Line mittelgreige', size: '7.5x7.5', id: '2699' },
      '33': 'bathroom/bpd_bathroom_L0022',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0022',
      '259': 'living-kitchen/bpd_livingkitchen_0022',
      '405_248': 'hallway-storage2/bpd_storage_hallway0022',
      '414': 'hallway-storage2/bpd_storage_hallway0022',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0022',
    },
    '3601_PL80_B_30': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Pure Line mittelgreige', size: '60x30', id: '2694' },
      '33': 'bathroom/bpd_bathroom_L0023',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0023',
      '259': 'living-kitchen/bpd_livingkitchen_0023',
      '405_248': 'hallway-storage2/bpd_storage_hallway0023',
      '414': 'hallway-storage2/bpd_storage_hallway0023',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0023',
    },
    '3601_PL80_B_60': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Pure Line mittelgreige', size: '60x60', id: '2693' },
      '33': 'bathroom/bpd_bathroom_L0024',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0024',
      '259': 'living-kitchen/bpd_livingkitchen_0024',
      '405_248': 'hallway-storage2/bpd_storage_hallway0024',
      '414': 'hallway-storage2/bpd_storage_hallway0024',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0024',
    },
    '3602_PKB_HR20_120': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Oak Park chalete', size: '120x30', id: '2793HR20' },
      '33': 'bathroom/bpd_bathroom_L0025',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0025',
      '259': 'living-kitchen/bpd_livingkitchen_0025',
      '405_248': 'hallway-storage2/bpd_storage_hallway0025',
      '414': 'hallway-storage2/bpd_storage_hallway0025',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0025',
    },
    '3602_PKB_HR80_120': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Oak Park cacao', size: '120x30', id: '2793HR80' },
      '33': 'bathroom/bpd_bathroom_L0026',
      '248': 'living-kitchen/bpd_livingkitchen_floortile0026',
      '259': 'living-kitchen/bpd_livingkitchen_0026',
      '405_248': 'hallway-storage2/bpd_storage_hallway0026',
      '414': 'hallway-storage2/bpd_storage_hallway0026',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0026',
    },
    '3000_T01_W': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Unit Two weiß matt', size: '60x30', id: '1571 TW 01' },
      '36_L': 'bathroom/bpd_bathroom_L0031',
      '36_MB': 'bathroom/bpd_bathroom_MB0031',
      '36_MS': 'bathroom/bpd_bathroom_MS0031',
      '36_S': 'bathroom/bpd_bathroom_S0031',
      '405_248': 'hallway-storage2/bpd_storage_hallway0031',
      '414': 'hallway-storage2/bpd_storage_hallway0031',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0031',
    },
    '3000_T02_W': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Unit Two weiß', size: '60x30', id: '1571 TW 02' },
      '36_L': 'bathroom/bpd_bathroom_L0032',
      '36_MB': 'bathroom/bpd_bathroom_MB0032',
      '36_MS': 'bathroom/bpd_bathroom_MS0032',
      '36_S': 'bathroom/bpd_bathroom_S0032',
      '405_248': 'hallway-storage2/bpd_storage_hallway0032',
      '414': 'hallway-storage2/bpd_storage_hallway0032',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0032',
    },
    '3101_SR90_W_60': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Gateway midnight black', size: '60x60', id: '2546SR90' },
      '36_L': 'bathroom/bpd_bathroom_L0033',
      '36_MB': 'bathroom/bpd_bathroom_MB0033',
      '36_MS': 'bathroom/bpd_bathroom_MS0033',
      '36_S': 'bathroom/bpd_bathroom_S0033',
      '405_248': 'hallway-storage2/bpd_storage_hallway0033',
      '414': 'hallway-storage2/bpd_storage_hallway0033',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0033',
    },
    '3101_SR90_W_30': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Gateway midnight black', size: '60x30', id: '2539SR90' },
      '36_L': 'bathroom/bpd_bathroom_L0034',
      '36_MB': 'bathroom/bpd_bathroom_MB0034',
      '36_MS': 'bathroom/bpd_bathroom_MS0034',
      '36_S': 'bathroom/bpd_bathroom_S0034',
      '405_248': 'hallway-storage2/bpd_storage_hallway0034',
      '414': 'hallway-storage2/bpd_storage_hallway0034',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0034',
    },
    '3101_SR90_W_M': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Gateway midnight black', size: '7.5x7.5', id: '2539SR90' },
      '36_L': 'bathroom/bpd_bathroom_L0164',
      '36_MB': 'bathroom/bpd_bathroom_MB0164',
      '36_MS': 'bathroom/bpd_bathroom_MS0164',
      '36_S': 'bathroom/bpd_bathroom_S0164',
    },
    '3100_CT10_W_60': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four creme', size: '60x60', id: '2361CT10' },
      '36_L': 'bathroom/bpd_bathroom_L0036',
      '36_MB': 'bathroom/bpd_bathroom_MB0036',
      '36_MS': 'bathroom/bpd_bathroom_MS0036',
      '36_S': 'bathroom/bpd_bathroom_S0036',
    },
    '3100_CT10_W_30': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four creme', size: '60x60', id: '2360CT10' },
      '36_L': 'bathroom/bpd_bathroom_L0037',
      '36_MB': 'bathroom/bpd_bathroom_MB0037',
      '36_MS': 'bathroom/bpd_bathroom_MS0037',
      '36_S': 'bathroom/bpd_bathroom_S0037',
    },
    '3100_CT10_W_M': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four creme', size: '5x5', id: '2363CT10' },
      '36_L': 'bathroom/bpd_bathroom_L0038',
      '36_MB': 'bathroom/bpd_bathroom_MB0038',
      '36_MS': 'bathroom/bpd_bathroom_MS0038',
      '36_S': 'bathroom/bpd_bathroom_S0038',
    },
    '3100_CT60_W_60': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four hellgrau', size: '60x60', id: '2361CT60' },
      '36_L': 'bathroom/bpd_bathroom_L0039',
      '36_MB': 'bathroom/bpd_bathroom_MB0039',
      '36_MS': 'bathroom/bpd_bathroom_MS0039',
      '36_S': 'bathroom/bpd_bathroom_S0039',
    },
    '3100_CT60_W_30': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four hellgrau', size: '60x30', id: '2360CT60' },
      '36_L': 'bathroom/bpd_bathroom_L0040',
      '36_MB': 'bathroom/bpd_bathroom_MB0040',
      '36_MS': 'bathroom/bpd_bathroom_MS0040',
      '36_S': 'bathroom/bpd_bathroom_S0040',
    },
    '3100_CT60_W_M': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four hellgrau', size: '5x5', id: '2363CT60' },
      '36_L': 'bathroom/bpd_bathroom_L0041',
      '36_MB': 'bathroom/bpd_bathroom_MB0041',
      '36_MS': 'bathroom/bpd_bathroom_MS0041',
      '36_S': 'bathroom/bpd_bathroom_S0041',
    },
    '3100_CT61_W_60': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four mittelgrau', size: '60x60', id: '2361CT61' },
      '36_L': 'bathroom/bpd_bathroom_L0042',
      '36_MB': 'bathroom/bpd_bathroom_MB0042',
      '36_MS': 'bathroom/bpd_bathroom_MS0042',
      '36_S': 'bathroom/bpd_bathroom_S0042',
    },
    '3100_CT61_W_30': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four mittelgrau', size: '60x30', id: '2360CT61' },
      '36_L': 'bathroom/bpd_bathroom_L0043',
      '36_MB': 'bathroom/bpd_bathroom_MB0043',
      '36_MS': 'bathroom/bpd_bathroom_MS0043',
      '36_S': 'bathroom/bpd_bathroom_S0043',
    },
    '3100_CT61_W_M': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four mittelgrau', size: '5x5', id: '2363CT61' },
      '36_L': 'bathroom/bpd_bathroom_L0044',
      '36_MB': 'bathroom/bpd_bathroom_MB0044',
      '36_MS': 'bathroom/bpd_bathroom_MS0044',
      '36_S': 'bathroom/bpd_bathroom_S0044',
    },
    '3100_CT62_W_60': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four dunkelgrau', size: '60x60', id: '2361CT62' },
      '36_L': 'bathroom/bpd_bathroom_L0045',
      '36_MB': 'bathroom/bpd_bathroom_MB0045',
      '36_MS': 'bathroom/bpd_bathroom_MS0045',
      '36_S': 'bathroom/bpd_bathroom_S0045',
    },
    '3100_CT62_W_30': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four dunkelgrau', size: '60x30', id: '2360CT62' },
      '36_L': 'bathroom/bpd_bathroom_L0046',
      '36_MB': 'bathroom/bpd_bathroom_MB0046',
      '36_MS': 'bathroom/bpd_bathroom_MS0046',
      '36_S': 'bathroom/bpd_bathroom_S0046',
    },
    '3100_CT62_W_M': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four dunkelgrau', size: '5x5', id: '2363CT62' },
      '36_L': 'bathroom/bpd_bathroom_L0047',
      '36_MB': 'bathroom/bpd_bathroom_MB0047',
      '36_MS': 'bathroom/bpd_bathroom_MS0047',
      '36_S': 'bathroom/bpd_bathroom_S0047',
    },
    '3501_LI01_SF': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Urbantones white', size: '30x10', id: '1670LI01' },
      '36_L': 'bathroom/bpd_bathroom_L0048',
      '36_MB': 'bathroom/bpd_bathroom_MB0048',
      '36_MS': 'bathroom/bpd_bathroom_MS0048',
      '36_S': 'bathroom/bpd_bathroom_S0048',
    },
    '3501_LI31_SF': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Urbantones light grey', size: '30x10', id: '1670LI31' },
      '36_L': 'bathroom/bpd_bathroom_L0049',
      '36_MB': 'bathroom/bpd_bathroom_MB0049',
      '36_MS': 'bathroom/bpd_bathroom_MS0049',
      '36_S': 'bathroom/bpd_bathroom_S0049',
    },
    '3501_-------': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Urbantones grey', size: '30x10', id: '1670LI61' },
      '36_L': 'bathroom/bpd_bathroom_L0050',
      '36_MB': 'bathroom/bpd_bathroom_MB0050',
      '36_MS': 'bathroom/bpd_bathroom_MS0050',
      '36_S': 'bathroom/bpd_bathroom_S0050',
    },

    '3600_SD7_W_120': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Hudson clay', size: '120x60', id: '2987SD7B' },
      '36_L': 'bathroom/bpd_bathroom_L0162',
      '36_MB': 'bathroom/bpd_bathroom_MB0162',
      '36_MS': 'bathroom/bpd_bathroom_MS0162',
      '36_S': 'bathroom/bpd_bathroom_S0162',
    },
    '3600_SD7_W_60': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Hudson clay', size: '60x60', id: '2987SD7B' },
      '36_L': 'bathroom/bpd_bathroom_L0144',
      '36_MB': 'bathroom/bpd_bathroom_MB0144',
      '36_MS': 'bathroom/bpd_bathroom_MS0144',
      '36_S': 'bathroom/bpd_bathroom_S0144',
    },
    '3600_SD7_W_30': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Hudson clay', size: '60x30', id: '2987SD7B' },
      '36_L': 'bathroom/bpd_bathroom_L0143',
      '36_MB': 'bathroom/bpd_bathroom_MB0143',
      '36_MS': 'bathroom/bpd_bathroom_MS0143',
      '36_S': 'bathroom/bpd_bathroom_S0143',
    },
    '3600_SD7B_W_SF': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Hudson clay', size: '60x7.5', id: '2852SD7B' },
      '36_L': 'bathroom/bpd_bathroom_L0141',
      '36_MB': 'bathroom/bpd_bathroom_MB0141',
      '36_MS': 'bathroom/bpd_bathroom_MS0141',
      '36_S': 'bathroom/bpd_bathroom_S0141',
    },
    '3600_SD7B_W_M': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Hudson clay', size: '60x7.5', id: '2852SD7B' },
      '36_L': 'bathroom/bpd_bathroom_L0142',
      '36_MB': 'bathroom/bpd_bathroom_MB0142',
      '36_MS': 'bathroom/bpd_bathroom_MS0142',
      '36_S': 'bathroom/bpd_bathroom_S0142',
    },

    '3500_C110': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Architectura 3.0 silk white', size: '15x15', id: '1260C110' },
      '36_L': 'bathroom/bpd_bathroom_L0053',
      '36_MB': 'bathroom/bpd_bathroom_MB0053',
      '36_MS': 'bathroom/bpd_bathroom_MS0053',
      '36_S': 'bathroom/bpd_bathroom_S0053',
    },
    '3500_C160': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Architectura 3.0 solid grey shiny', size: '60x20', id: '1260C190' },
      '36_L': 'bathroom/bpd_bathroom_L0054',
      '36_MB': 'bathroom/bpd_bathroom_MB0054',
      '36_MS': 'bathroom/bpd_bathroom_MS0054',
      '36_S': 'bathroom/bpd_bathroom_S0054',
    },
    '3501_----': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Architectura 3.0 solid clay brown', size: '60x20', id: '1260C171' },
      '36_L': 'bathroom/bpd_bathroom_L0055',
      '36_MB': 'bathroom/bpd_bathroom_MB0055',
      '36_MS': 'bathroom/bpd_bathroom_MS0055',
      '36_S': 'bathroom/bpd_bathroom_S0055',
    },
    // '3601_PL80_W_SF': {
    //   'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Pure Line mittelgreige', size: '60x15', id: '2692' },
    //   '36_L': 'bathroom/bpd_bathroom_L0056',
    //   '36_MB': 'bathroom/bpd_bathroom_MB0056',
    //   '36_MS': 'bathroom/bpd_bathroom_MS0056',
    //   '36_S': 'bathroom/bpd_bathroom_S0056',
    // },
    '3601_PL80_W_M': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Pure Line mittelgreige', size: '7.5x7.5', id: '2699' },
      '36_L': 'bathroom/bpd_bathroom_L0057',
      '36_MB': 'bathroom/bpd_bathroom_MB0057',
      '36_MS': 'bathroom/bpd_bathroom_MS0057',
      '36_S': 'bathroom/bpd_bathroom_S0057',
    },
    '3601_PL80_W_30': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Pure Line mittelgreige', size: '60x30', id: '2694' },
      '36_L': 'bathroom/bpd_bathroom_L0058',
      '36_MB': 'bathroom/bpd_bathroom_MB0058',
      '36_MS': 'bathroom/bpd_bathroom_MS0058',
      '36_S': 'bathroom/bpd_bathroom_S0058',
    },
    '3601_PL80_W_60': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Pure Line mittelgreige', size: '60x60', id: '2693' },
      '36_L': 'bathroom/bpd_bathroom_L0059',
      '36_MB': 'bathroom/bpd_bathroom_MB0059',
      '36_MS': 'bathroom/bpd_bathroom_MS0059',
      '36_S': 'bathroom/bpd_bathroom_S0059',
    },
    // '3601_---------': {
    //   'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Pure Line mittelgreige', size: '120x60', id: '2690' },
    //   '36_L': 'bathroom/bpd_bathroom_L0060',
    //   '36_MB': 'bathroom/bpd_bathroom_MB0060',
    //   '36_MS': 'bathroom/bpd_bathroom_MS0060',
    //   '36_S': 'bathroom/bpd_bathroom_S0060',
    // },
    'F01_010101_09_3060': {
      'info': { type: 'floortiles', manufacturer: 'Kermos', name: 'Metro grau', size: '60x30', id: '1025918' },
      '33': 'bathroom/bpd_bathroom_L_nurnberg0030', // Metro grau
      '248': 'living-kitchen/bpb_livingkitchen_0039',
      '259': 'living-kitchen/bpb_livingkitchen_floortile_0042',
      '405_248': 'hallway-storage2/bpd_storage_hallway_nurnberg0004',
      '414': 'hallway-storage2/bpd_storage_hallway_nurnberg0004',
      '427': 'hallway-storage2/bpd_storage_nurnberg0027',
    },
    'F01_010101_06_3060': {
      'info': { type: 'floortiles', manufacturer: 'Kermos', name: 'Metro braun', size: '60x30', id: '1025935' },
      '33': 'bathroom/bpd_bathroom_L_nurnberg0000_kermosbraun',
      '248': 'living-kitchen/bpb_livingkitchen_0000_kermosbraun',
      '259': 'living-kitchen/bpb_livingkitchen_floortile_0000_kermosbraun',
      '405_248': 'hallway-storage2/bpd_storage_hallway_nurnberg00_kermosbraun',
      '414': 'hallway-storage2/bpd_storage_hallway_nurnberg00_kermosbraun',
      '427': 'hallway-storage2/bpd_storage_nurnberg0000_kermosbraun',
    },
    'F01_010101_03_3060': {
      'info': { type: 'floortiles', manufacturer: 'Kermos', name: 'Metro schlamm', size: '60x30', id: '1025929' },
      '33': 'bathroom/bpd_bathroom_L_nurnberg0031',
      '248': 'living-kitchen/bpb_livingkitchen_0038',
      '259': 'living-kitchen/bpb_livingkitchen_floortile_0041',
      '405_248': 'hallway-storage2/bpd_storage_hallway_nurnberg0028',
      '414': 'hallway-storage2/bpd_storage_hallway_nurnberg0028',
      '427': 'hallway-storage2/bpd_storage_nurnberg0028',
    },
    'F01_010101_03_6060': {
      'info': { notice: 'dummy copy of F01_010101_03_3060' },
      '33': 'bathroom/bpd_bathroom_L_nurnberg0031',
      '248': 'living-kitchen/bpb_livingkitchen_0038',
      '259': 'living-kitchen/bpb_livingkitchen_floortile_0041',
      '405_248': 'hallway-storage2/bpd_storage_hallway_nurnberg0028',
      '414': 'hallway-storage2/bpd_storage_hallway_nurnberg0028',
      '427': 'hallway-storage2/bpd_storage_nurnberg0028',
    },
    'F01_020101_09_3060': {
      'info': { type: 'walltiles', manufacturer: 'Kermos', name: 'Metro grau', size: '60x30', id: '1025918' },
      '36_L': 'bathroom/bpd_bathroom_L_nurnberg0064',
      '36_MB': 'bathroom/bpd_bathroom_MB_nunberg_wandflisen0064',
      '36_MS': 'bathroom/bpd_bathroom_MS_nunberg_wandflisen0064',
      '36_S': 'bathroom/bpd_bathroom_S_nunberg_wandflisen0064',
    },
    'F01_020101_06_3060': {
      'info': { type: 'walltiles', manufacturer: 'Kermos', name: 'Metro braun', size: '60x30', id: '1025935' },
      '36_L': 'bathroom/bpd_bathroom_L_nurnberg0000_kermosbraunwand',
      '36_MB': 'bathroom/bpd_bathroom_MB_nurnberg0000_kermosbraunwand',
      '36_MS': 'bathroom/bpd_bathroom_MS_nurnberg0000_kermosbraunwand',
      '36_S': 'bathroom/bpd_bathroom_S_nurnberg0000_kermosbraunwand',
    },
    'F01_020101_03_3060': {
      'info': { type: 'walltiles', manufacturer: 'Kermos', name: 'Metro schlamm', size: '60x30', id: '1025929' },
      '36_L': 'bathroom/bpd_bathroom_L_nurnberg0065',
      '36_MB': 'bathroom/bpd_bathroom_MB_nunberg_wandflisen0065',
      '36_MS': 'bathroom/bpd_bathroom_MS_nunberg_wandflisen0065',
      '36_S': 'bathroom/bpd_bathroom_S_nunberg_wandflisen0065',
    },
    '3100_CT70_W_30': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four greige', size: '60x30', id: '2360CT70' },
      '36_L': 'bathroom/bpd_bathroom_L0230',
      '36_MB': 'bathroom/bpd_bathroom_MB0165',
      '36_MS': 'bathroom/bpd_bathroom_MS0165',
      '36_S': 'bathroom/bpd_bathroom_S0165',
    },
    '3100_CT80_W_30': {
      'info': { type: 'walltiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four dunkelbraun', size: '60x30', id: '2360CT80' },
      '36_L': 'bathroom/bpd_bathroom_L0231',
      '36_MB': 'bathroom/bpd_bathroom_MB0166',
      '36_MS': 'bathroom/bpd_bathroom_MS0166',
      '36_S': 'bathroom/bpd_bathroom_S0166',
    },
    '3100_CT70_B_30': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four greige', size: '60x30', id: '2360CT70' },
      '33': 'bathroom/bpd_bathroom_L0232',
      '248': 'living-kitchen/bpd_livingkitchen_0047',
      '259': 'living-kitchen/bpd_livingkitchen_floortile_0043',
      '405_248': 'hallway-storage2/bpd_storage_hallway0092',
      '414': 'hallway-storage2/bpd_storage_hallway0092',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0092',
    },
    '3100_CT80_B_30': {
      'info': { type: 'floortiles', manufacturer: 'Villeroy & Boch', name: 'Unit Four dunkelbraun', size: '60x30', id: '2360CT80' },
      '33': 'bathroom/bpd_bathroom_L0233',
      '248': 'living-kitchen/bpd_livingkitchen_0048',
      '259': 'living-kitchen/bpd_livingkitchen_floortile_0044',
      '405_248': 'hallway-storage2/bpd_storage_hallway0093',
      '414': 'hallway-storage2/bpd_storage_hallway0093',
      '427': 'hallway-storage2/bpd_storage_hallway_storage0093',
    },
    'F01_020101_03_6060': {
      'info': { notice: 'dummy copy of F01_020101_03_3060' },
      '36_L': 'bathroom/bpd_bathroom_L_nurnberg0065',
      '36_MB': 'bathroom/bpd_bathroom_MB_nunberg_wandflisen0065',
      '36_MS': 'bathroom/bpd_bathroom_MS_nunberg_wandflisen0065',
      '36_S': 'bathroom/bpd_bathroom_S_nunberg_wandflisen0065',
    },
    '2300_CH': {
      'info': { type: 'showerarmature', manufacturer: 'Grohe', name: 'Eurostyle Cosmopolitan Brausenarmatur', id: '33590002; 27788002' },
      '293_L': 'bathroom/bpd_bathroom_L0065',
      '293_MS': 'bathroom/bpd_bathroom_MS0070',
    },
    '2302_CH': {
      'info': { type: 'showerarmature', manufacturer: 'Grohe', name: 'Essence Brausenarmatur Thermostat', id: '34776000; 27789002; (34784000)' },
      '293_L': 'bathroom/bpd_bathroom_L0066',
      '293_MS': 'bathroom/bpd_bathroom_MS0066',
    },
    '2800_CH': {
      'info': { type: 'showerarmature', manufacturer: 'Grohe', name: 'Essence Brausenarmatur', id: '33636001; 26594000' },
      '293_L': 'bathroom/bpd_bathroom_L0067',
      '293_MS': 'bathroom/bpd_bathroom_MS0067',
    },
    '2804_CH': {
      'info': { type: 'showerarmature', manufacturer: 'Grohe', name: 'Essence Brausenarmatur Unterputz mit Kopfbrause', id: '24058001; 26066000; 26580000; 27400000' },
      '293_L': 'bathroom/bpd_bathroom_L0068',
      '293_MS': 'bathroom/bpd_bathroom_MS0068',
    },
    '2305': {
      'info': { type: 'showerarmature', manufacturer: 'Grohe', name: '', id: '24052002; 27588002; 28671000; 26412000' },
      '293_L': 'bathroom/bpd_bathroom_L0069',
      '293_MS': 'bathroom/bpd_bathroom_MS0069',
    },
    // rendering doesn’t look right -> commented out (carlcs 21-12-06)
    // 'A02_050410_12_SZ': {
    //   'info': { type: 'showerarmature', manufacturer: 'Grohe', name: '', id: '24045000' },
    //   '293_L': 'bathroom/bpd_bathroom_L_nurnberg0069',
    //   '293_MS': 'bathroom/bpd_bathroom_MS_nurnberg0069',
    // },
    '2200_CH': {
      'info': { type: 'bathtubarmature', manufacturer: 'Grohe', name: 'Eurostyle Cosmopolitan Badewannenarmatur', id: '3359220A' },
      '16': 'bathroom/bpd_bathroom_L0074',
    },
    '2400_CH': {
      'info': { type: 'bathtubarmature', manufacturer: 'Grohe', name: 'Eurostyle Cosmopolitan Badewannenarmatur Unterputz', id: '24052002; 27588002; 28671000; (24051002)' },
      '16': 'bathroom/bpd_bathroom_L0075',
    },
    '2700_CH': {
      'info': { type: 'bathtubarmature', manufacturer: 'Grohe', name: 'Essence Badewannenarmatur', id: '33624001; 26580000; 28362000' },
      '16': 'bathroom/bpd_bathroom_L0076',
    },
    '2703_CH': {
      // Eigentlich Essence Badewannenarmatur Unterputz / hier aber als Brausenarmatur hergestellt, daher 2703_CH (neu: 2802_CH !!!!)
      'info': { type: 'bathtubarmature', manufacturer: 'Grohe', name: 'Essence Brausenarmatur', id: '24058001; 26171000; 26580000' },
      '16': 'bathroom/bpd_bathroom_L0078',
    },
    '2704_CH': {
      // Essence Badewannenarmatur / 2700_CH -> in NRW nicht mehr im Sortiment -> ggf. als 2803_CH (neu) verwendbar?
      'info': { type: 'bathtubarmature', manufacturer: 'Grohe', name: 'Essence Badewannenarmatur', id: '26657000' },
      '16': 'bathroom/bpd_bathroom_L0077', // bathtub
      '293_L': 'bathroom/bpd_bathroom_L0185', // shower
      '293_MS': 'bathroom/bpd_bathroom_MS0077', // shower
    },
    // rendering doesn’t look right -> commented out (carlcs 21-12-06)
    // 'W04_180307_12_SZ': {
    //   'info': { type: 'bathtubarmature', manufacturer: 'Hansgrohe', name: 'Essence Badewannenarmatur Unterputz', id: '32475000' },
    //   '16': 'bathroom/bpd_bathroom_nurnberg0079',
    // },
    '2002_CH': {
      'info': { type: 'washbasinarmature', manufacturer: 'Grohe', name: 'Eurostyle Cosmopolitan Chrom', id: '23043003' },
      '56': 'bathroom/bpd_bathroom_L0083',
    },
    '2500_AL1': {
      'info': { type: 'washbasinarmature', manufacturer: 'Grohe', name: 'Essence Hard Graphit', id: '23589AL1' },
      '56': 'bathroom/bpd_bathroom_L0085',
    },
    '2500_DC1': {
      'info': { type: 'washbasinarmature', manufacturer: 'Grohe', name: 'Essence Super Steel', id: '23589DC1' },
      '56': 'bathroom/bpd_bathroom_L0086',
    },
    '2500_CH': {
      'info': { type: 'washbasinarmature', manufacturer: 'Grohe', name: 'Essence Chrom', id: '23589001' },
      '56': 'bathroom/bpd_bathroom_L0087',
    },
    '2501_AL1': {
      'info': { type: 'washbasinarmature', manufacturer: 'Grohe', name: 'Essence M-Size Hard Graphit', id: '23462AL1' },
      '56': 'bathroom/bpd_bathroom_L0089',
    },
    '2501_DC1': {
      'info': { type: 'washbasinarmature', manufacturer: 'Grohe', name: 'Essence M-Size Super Steel', id: '23462DC1' },
      '56': 'bathroom/bpd_bathroom_L0084',
    },
    '2503_CH': {
      'info': { type: 'washbasinarmature', manufacturer: 'Grohe', name: 'Essence Super Steel Unterputz', id: '19408DC1' },
      '56': 'bathroom/bpd_bathroom_L0088',
    },
    'A02_040306_12_SSize': {
      'info': { type: 'washbasinarmature', manufacturer: 'Grohe', name: 'Eurosmart Cosmopolitan chrom', id: '2337700E' },
      '56': 'bathroom/bpd_bathroom_L_nurnberg0091',
    },
    '2000_CH': {
      'info': { type: 'washbasinarmature', manufacturer: 'Grohe', name: 'Eurostyle Cosmopolitan', id: '3355220E' },
      '56': 'bathroom/bpd_bathroom_L0093',
    },
    'A02_040306_12_Msize_ST': {
      'info': { type: 'washbasinarmature', manufacturer: 'Laufen', name: 'Pure', id: '' },
      '56': 'bathroom/bpd_bathroom_L0240',
    },
    '9102_01': {
      'info': { type: 'washbasin', manufacturer: 'Villeroy & Boch', name: 'Avento', size: '60cm', id: '415860' },
      '54': 'bathroom/bpd_bathroom_L0179',
    },
    '1000_01': {
      'info': { type: 'washbasin', manufacturer: 'Villeroy & Boch', name: 'Architectura', size: '60cm', id: '418860' },
      '54': 'bathroom/bpd_bathroom_L0096',
    },
    '1003_01': {
      'info': { type: 'washbasin', manufacturer: 'Villeroy & Boch', name: 'Architectura', size: '130cm', id: '611813' },
      '54': 'bathroom/bpd_bathroom_L0097',
    },
    '1503_01': {
      'info': { type: 'washbasin', manufacturer: 'Villeroy & Boch', name: 'Subway 2.0', size: '100cm', id: '7175A0' },
      '54': 'bathroom/bpd_bathroom_L0098',
    },
    '9103_01': {
      'info': { type: 'washbasin', manufacturer: 'Villeroy & Boch', name: 'Avento', size: '80cm', id: '415680' },
      '54': 'bathroom/bpd_bathroom_L0099',
    },
    '1500_01': {
      'info': { type: 'washbasin', manufacturer: 'Villeroy & Boch', name: 'Subway 2.0', size: '60cm', id: '7176D2' },
      '54': 'bathroom/bpd_bathroom_L0181',
    },
    'W04_080718_01_600465': {
      'info': { type: 'washbasin', manufacturer: 'Laufen', name: 'Pro S', size: '60cm', id: 'H8109630001041' },
      '54': 'bathroom/bpd_bathroom_L_nurnberg0101',
    },
    'W04_080718_01_850460': {
      'info': { type: 'washbasin', manufacturer: 'Laufen', name: 'Pro S', size: '85cm', id: 'H8139650001041' },
      '54': 'bathroom/bpd_bathroom_L_nurnberg0102',
    },
    '1000_01_B': {
      'info': { type: 'washbasin', manufacturer: 'Ideal Standard', name: 'Connect Air Cube', size: '60cm', id: 'E029801' },
      '54': 'bathroom/bpd_bathroom_L0216',
    },
    '1001_01': {
      'info': { type: 'washbasin', manufacturer: 'Ideal Standard', name: 'Connect Air Cube', size: '50cm', id: 'E030101' },
      '54': 'bathroom/bpd_bathroom_L0219',
    },
    '1002_01': {
      'info': { type: 'washbasin', manufacturer: 'Ideal Standard', name: 'Connect Air Cube', size: '40cm', id: 'E030701' },
      '54': 'bathroom/bpd_bathroom_L0213',
    },
    'W04_080718_01_600465_na_K': {
      'info': { type: 'washbasin', manufacturer: 'Laufen', name: 'VAL', size: '60cm', id: '' },
      '54': 'bathroom/bpd_bathroom_L0250',
    },
    'E0307_3DPL_NN': {
      'info': { type: 'washbasin', manufacturer: 'Ideal Standard', name: 'Connect Air', size: '40cm', id: 'E0307_3DPL_NN' },
      '54': 'bathroom/bpd_bathroom_L0252',
    },
    'E0298_3DPL_NN': {
      'info': { type: 'washbasin', manufacturer: 'Ideal Standard', name: 'Connect Air', size: '60cm', id: 'E0298_3DPL_NN' },
      '54': 'bathroom/bpd_bathroom_L0253',
    },
    '1101_01_R': {
      'info': { type: 'wc', manufacturer: 'Villeroy & Boch', name: 'Architectura', id: '5684R201' },
      '60': 'bathroom/bpd_bathroom_L0105',
    },
    '1600_01': {
      'info': { type: 'wc', manufacturer: 'Villeroy & Boch', name: 'Subway 2.0', id: '5614R001' },
      '60': 'bathroom/bpd_bathroom_L0106',
    },
    'W03_070718_01_SZ': {
      'info': { type: 'wc', manufacturer: 'Laufen', name: 'Pro S', id: 'H8209620000001' },
      '60': 'bathroom/bpd_bathroom_L_nurnberg0108',
    },
    '1003_01_B': {
      'info': { type: 'wc', manufacturer: 'Ideal Standard', name: 'Connect Air Cube', id: 'E228801' },
      '60': 'bathroom/bpd_bathroom_L0222',
    },
    'E2288_3DPL_NN': {
      'info': { type: 'wc', manufacturer: 'Ideal Standard', name: 'Connect Air', id: 'E2288_3DPL_NN' },
      '60': 'bathroom/bpd_bathroom_L0251',
    },
    '2470_11': {
      'info': { type: 'wcpressurepad', manufacturer: 'Geberit', name: 'Sigma 01 weiß alpin', id: '115.770.11.5' },
      '64': 'bathroom/bpd_bathroom_L0112',
    },
    '2970_KJ': {
      'info': { type: 'wcpressurepad', manufacturer: 'Geberit', name: 'Sigma 30 weiß', id: '115.883.KJ.1' },
      '64': 'bathroom/bpd_bathroom_L0113',
    },
    '2970_KM': {
      'info': { type: 'wcpressurepad', manufacturer: 'Geberit', name: 'Sigma 30 schwarz', id: '115.883.KM.1' },
      '64': 'bathroom/bpd_bathroom_L0116',
    },
    '5000_01': {
      'info': { type: 'wcpressurepad', manufacturer: 'Grohe', name: 'Skate Cosmopolitan S chrom', id: '37535000' },
      '64': 'bathroom/bpd_bathroom_L0210',
    },
    '5001_01': {
      'info': { type: 'wcpressurepad', manufacturer: 'Grohe', name: 'Skate Cosmopolitan S weiß', id: '37535SH0' },
      '64': 'bathroom/bpd_bathroom_L0207',
    },
    '5002_01': {
      'info': { type: 'wcpressurepad', manufacturer: 'Grohe', name: 'Arena S chrom', id: '37624000' },
      '64': 'bathroom/bpd_bathroom_L0204',
    },
    '5003_01': {
      'info': { type: 'wcpressurepad', manufacturer: 'Grohe', name: 'Arena S weiß', id: '37624SH0' },
      '64': 'bathroom/bpd_bathroom_L0201',
    },
    '1200_01': {
      'info': { type: 'bathtub', manufacturer: 'Geberit', name: 'Architectura Solo', id: 'UBA 170 ARA 2V-01' },
      '374_L': 'bathroom/bpd_bathroom_L0118',
      '374_MB': 'bathroom/bpd_bathroom_MB0118',
    },
    '1320': {
      'info': { type: 'showerbasin', manufacturer: 'Dallmer', name: '', id: '511317' },
      'influences': [{ renderId: 'Foreground', suffix: '_F' }],
      '392_L': 'bathroom/bpd_bathroom_L0123',
      '392_MS': 'bathroom/bpd_bathroom_MS0123',
    },
    '---0': {
      'info': { type: 'showerbasin', manufacturer: 'Dallmer', name: '', id: '535177' },
      '392_L': 'bathroom/bpd_bathroom_L0124',
      '392_MS': 'bathroom/bpd_bathroom_MS0124',
    },
    '1310': {
      'info': { type: 'showerbasin', manufacturer: 'Dallmer', name: '', id: '538208' },
      'influences': [{ renderId: 'Foreground', suffix: '_F' }],
      '392_L': 'bathroom/bpd_bathroom_L0125',
      '392_MS': 'bathroom/bpd_bathroom_MS0125',
    },
    '1808': {
      'info': { type: 'showerbasin', manufacturer: 'Dallmer', name: '', id: '517258' },
      'influences': [{ renderId: 'Foreground', suffix: '_F' }],
      '392_L': 'bathroom/bpd_bathroom_L0190',
      '392_MS': 'bathroom/bpd_bathroom_MS0190',
    },
    '1301_01_01': {
      'info': { type: 'showerbasin', manufacturer: 'Villeroy & Boch', name: '', id: 'UDA 9090 ARA 115V-01' },
      'influences': [{ renderId: 'Foreground', suffix: '_F' }],
      '392_L': 'bathroom/bpd_bathroom_L0126',
      '392_MS': 'bathroom/bpd_bathroom_MS0126',
    },
    '1800_1S_03': {
      'info': { type: 'showerbasin', manufacturer: 'Villeroy & Boch', name: 'Infinity', id: 'UDQ 1290 SQI 2V-1S' },
      '392_L': 'bathroom/bpd_bathroom_L0131',
      '392_MS': 'bathroom/bpd_bathroom_MS0129',
    },
    '1300_01_01': {
      'info': { type: 'showerbasin', manufacturer: 'Villeroy & Boch', name: 'Impreso', id: 'UDA 9090 EXP 1V-01' },
      '392_L': 'bathroom/bpd_bathroom_L0133',
      '392_MS': 'bathroom/bpd_bathroom_MS0132',
    },
    '1480': {
      'info': { type: 'radiator', manufacturer: 'Kermi', name: '', id: 'E0E10120060 2D XK' },
      '69': 'bathroom/bpd_bathroom_L0187',
    },
    '1981': {
      'info': { type: 'radiator', manufacturer: 'Zehnder', name: '', id: 'SUBE-180-60/GD' },
      '69': 'bathroom/bpd_bathroom_L0134',
    },
    '6000': {
      'info': { type: 'parquet', manufacturer: 'Bauwerk', name: 'Monopark 13 matt versiegelt', id: '1000 5323' },
      '78': 'living-kitchen/bpd_livingkitchen_0139', //     | Wohnbereich
      '254': null, //                                       | Küche
      '408': null, //                                       | Flur
      '423': null, //                                       | Abstellraum
      '405_78': 'hallway-storage2/bpd_storage_hallway0139', //| Flur
      '421_78': 'hallway-storage2/bpd_storage_hallway_storage0139', //| Abstellraum
      '228_bedroom_floor': 'hallway-storage2/bpd_storage_hallway_bedroom0139', //| Schlafzimmer
    },
    '6002': {
      'info': { type: 'parquet', manufacturer: 'Bauwerk', name: 'Monopark Farina 15 naturgeölt', id: '1001 6944' },
      '78': 'living-kitchen/bpd_livingkitchen_0140', //     | Wohnbereich
      '254': null, //                                       | Küche
      '408': null, //                                       | Flur
      '423': null, //                                       | Abstellraum
      '405_78': 'hallway-storage2/bpd_storage_hallway0140', //| Flur
      '421_78': 'hallway-storage2/bpd_storage_hallway_storage0140', //| Abstellraum
      '228_bedroom_floor': 'hallway-storage2/bpd_storage_hallway_bedroom0140', //| Schlafzimmer
    },
    '6005': {
      'info': { type: 'parquet', manufacturer: 'Bauwerk', name: 'Monopark Mandorla 15 naturgeölt', id: '1011 7160' },
      '78': 'living-kitchen/bpd_livingkitchen_0141', //     | Wohnbereich
      '254': null, //                                       | Küche
      '408': null, //                                       | Flur
      '423': null, //                                       | Abstellraum
      '405_78': 'hallway-storage2/bpd_storage_hallway0141', //| Flur
      '421_78': 'hallway-storage2/bpd_storage_hallway_storage0141', //| Abstellraum
      '228_bedroom_floor': 'hallway-storage2/bpd_storage_hallway_bedroom0141', //| Schlafzimmer
    },
    '6050': {
      'info': { type: 'parquet', manufacturer: 'Bauwerk', name: 'Monopark Crema 15 B-Protect', id: '1002 3902' },
      '78': 'living-kitchen/bpd_livingkitchen_0142', //     | Wohnbereich
      '254': null, //                                       | Küche
      '408': null, //                                       | Flur
      '423': null, //                                       | Abstellraum
      '405_78': 'hallway-storage2/bpd_storage_hallway0142', //| Flur
      '421_78': 'hallway-storage2/bpd_storage_hallway_storage0142', //| Abstellraum
      '228_bedroom_floor': 'hallway-storage2/bpd_storage_hallway_bedroom0142', //| Schlafzimmer
    },
    '6004': {
      'info': { type: 'parquet', manufacturer: 'Bauwerk', name: 'Cleverpark 14 matt versiegelt', id: '1001 1650' },
      '78': 'living-kitchen/bpd_livingkitchen_0143', //     | Wohnbereich
      '254': null, //                                       | Küche
      '408': null, //                                       | Flur
      '423': null, //                                       | Abstellraum
      '405_78': 'hallway-storage2/bpd_storage_hallway0143', //| Flur
      '421_78': 'hallway-storage2/bpd_storage_hallway_storage0143', //| Abstellraum
      '228_bedroom_floor': 'hallway-storage2/bpd_storage_hallway_bedroom0143', //| Schlafzimmer
    },
    '6055': {
      'info': { type: 'parquet', manufacturer: 'Bauwerk', name: 'Cleverpark Mandorla 34 naturgeölt', id: '1002 2968' },
      '78': 'living-kitchen/bpd_livingkitchen_0144', //     | Wohnbereich
      '254': null, //                                       | Küche
      '408': null, //                                       | Flur
      '423': null, //                                       | Abstellraum
      '405_78': 'hallway-storage2/bpd_storage_hallway0144', //| Flur
      '421_78': 'hallway-storage2/bpd_storage_hallway_storage0144', //| Abstellraum
      '228_bedroom_floor': 'hallway-storage2/bpd_storage_hallway_bedroom0144', //| Schlafzimmer
    },
    '6553': {
      'info': { type: 'parquet', manufacturer: 'Bauwerk', name: 'Cleverpark geräuchert 14 naturgeölt', id: '1001 9903' },
      '78': 'living-kitchen/bpd_livingkitchen_0145', //     | Wohnbereich
      '254': null, //                                       | Küche
      '408': null, //                                       | Flur
      '423': null, //                                       | Abstellraum
      '405_78': 'hallway-storage2/bpd_storage_hallway0145', //| Flur
      '421_78': 'hallway-storage2/bpd_storage_hallway_storage0145', //| Abstellraum
      '228_bedroom_floor': 'hallway-storage2/bpd_storage_hallway_bedroom0145', //| Schlafzimmer
    },
    '6550': {
      'info': { type: 'parquet', manufacturer: 'Bauwerk', name: 'Studiopark tief gebürstet naturgeölt', id: '1013 7630' },
      '78': 'living-kitchen/bpd_livingkitchen_0146', //     | Wohnbereich
      '254': null, //                                       | Küche
      '408': null, //                                       | Flur
      '423': null, //                                       | Abstellraum
      '405_78': 'hallway-storage2/bpd_storage_hallway0146', //| Flur
      '421_78': 'hallway-storage2/bpd_storage_hallway_storage0146', //| Abstellraum
      '228_bedroom_floor': 'hallway-storage2/bpd_storage_hallway_bedroom0146', //| Schlafzimmer
    },
    '9403': {
      'info': { type: 'parquet', manufacturer: 'Bauwerk', name: 'Villapark tief gebürstet naturgeölt', id: '1011 5654' },
      '78': 'living-kitchen/bpd_livingkitchen_0147', //     | Wohnbereich
      '254': null, //                                       | Küche
      '408': null, //                                       | Flur
      '423': null, //                                       | Abstellraum
      '405_78': 'hallway-storage2/bpd_storage_hallway0147', //| Flur
      '421_78': 'hallway-storage2/bpd_storage_hallway_storage0147', //| Abstellraum
      '228_bedroom_floor': 'hallway-storage2/bpd_storage_hallway_bedroom0147', //| Schlafzimmer
    },
    '500X_Bo': {
      'info': { type: 'doorhandle', manufacturer: 'Hoppe', name: 'Bonn', id: '3289778' },
      '97': 'living-kitchen/bpd_livingkitchen_doorhandle0033',
    },
    '500X_Am': {
      'info': { type: 'doorhandle', manufacturer: 'Hoppe', name: 'Amsterdam', id: '3289719' },
      '97': 'living-kitchen/bpd_livingkitchen_doorhandle0034',
    },
    '0320_GR': {
      'info': { type: 'livingroomdoor', manufacturer: '', name: 'weiß normal', id: '' },
      '263': 'living-kitchen/bpd_livingkitchen_door0030',
    },
    '0335_GR': {
      'info': { type: 'livingroomdoor', manufacturer: '', name: 'satiniert normal', id: '' },
      '263': 'living-kitchen/bpd_livingkitchen_door0031',
    },
    '0330_GR': {
      'info': { type: 'livingroomdoor', manufacturer: '', name: 'klarglass normal', id: '' },
      '263': 'living-kitchen/bpd_livingkitchen_door0032',
    },
    '0320_GR_K': {
      'info': { type: 'livingroomdoor', manufacturer: '', name: 'weiß bündig', id: '' },
      '263': 'living-kitchen/bpd_livingkitchen_door0061',
    },
    '6400': {
      'info': { type: 'baseboards', manufacturer: '', name: 'weiß', id: '' },
      '80': 'living-kitchen/bpd_livingkitchen_whitefussleis0016',
    },
    // '7200_RAL9010': {
    //   'info': { type: 'wallcolor', manufacturer: '', name: 'RAL9010', id: '' },
    //   '93': null, // 'living-kitchen/bpd_livingkitchen_bg_RAL9010', // RAL9010
    // },
  },
};
